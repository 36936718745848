import React, { useContext, useState, useEffect } from "react"
import UserContext from "../context/userContext/UserContext"

export default function useClaim() {
  const [daysClaim, setDaysClaim] = useState({ msg: "", active: false })
  const { contract, account } = useContext(UserContext)
  const [loading, setLoading] = useState(false)
  const [step, setStep] = useState(1)
  const [form, setForm] = useState({
    cotton: 0, //0
    wool: 0, // 1
    leather: 0, //2
    leave: 0, //3
    flax: 0, //4
    silk: 0, //5
  })

  const [inventoryFarm, setInventoryFarm] = useState({})

  // TODO: These prices are in BUSD. They need to take into account the `getCurrentPrice` function to give an exact amount in PEACH
  const price = {
    cotton: 1,
    wool: 1,
    leather: 1,
    leave: 1,
    flax: 1,
    silk: 1,
  }

  useEffect(() => {
    if (contract && account) {
      let arrayClaimBalances = []

      let tempInventoryFarm = {}

      getDayClaim()

      arrayClaimBalances.push(
        contract.contractDWFarmingGoods.methods
          .specificBalanceOf(account, 0)
          .call()
          .then(result => (tempInventoryFarm["cotton"] = parseInt(result)))
      )
      arrayClaimBalances.push(
        contract.contractDWFarmingGoods.methods
          .specificBalanceOf(account, 1)
          .call()
          .then(result => (tempInventoryFarm["wool"] = parseInt(result)))
      )
      arrayClaimBalances.push(
        contract.contractDWFarmingGoods.methods
          .specificBalanceOf(account, 2)
          .call()
          .then(result => (tempInventoryFarm["leather"] = parseInt(result)))
      )
      arrayClaimBalances.push(
        contract.contractDWFarmingGoods.methods
          .specificBalanceOf(account, 3)
          .call()
          .then(result => (tempInventoryFarm["leave"] = parseInt(result)))
      )
      arrayClaimBalances.push(
        contract.contractDWFarmingGoods.methods
          .specificBalanceOf(account, 4)
          .call()
          .then(result => (tempInventoryFarm["flax"] = parseInt(result)))
      )
      arrayClaimBalances.push(
        contract.contractDWFarmingGoods.methods
          .specificBalanceOf(account, 5)
          .call()
          .then(result => (tempInventoryFarm["silk"] = parseInt(result)))
      )

      Promise.all(arrayClaimBalances)
        .then(result => setInventoryFarm(tempInventoryFarm))
        .catch(err => console.error(err))
    }
  }, [contract, account])

  const handleAllPeach = () => {
    let tempResult = 0

    Object.keys(form).map(
      element => (tempResult = tempResult + form[element] * price[element])
    )
    console.log(daysClaim)
    if (daysClaim.commision < 0) return tempResult

    let commission = (tempResult * daysClaim.commision) / 100

    return tempResult - commission
  }

  const handleClaimPeach = () => {
    if (contract) {
      setLoading(true)
      contract.contractDWGameHarvest.methods
        .sellHarvestToOracle([
          form.cotton,
          form.wool,
          form.leather,
          form.leave,
          form.flax,
          form.silk,
        ])
        .send({ from: account })
        .then(result => {
          setStep(3)
          setLoading(false)
        })
        .catch(err => setLoading(false))
    }
  }

  const handleUpdateAdd = name =>
    setForm(prevState => ({
      ...prevState,
      [name]:
        prevState[name] < inventoryFarm[name]
          ? prevState[name] + 1
          : prevState[name],
    }))

  const handleUpdateRest = name =>
    setForm(prevState => ({
      ...prevState,
      [name]: prevState[name] > 0 ? prevState[name] - 1 : 0,
    }))

  const handleUpdateAll = name =>
    setForm(prevState => ({ ...prevState, [name]: inventoryFarm[name] }))

  const getDayClaim = () => {
    if (contract)
      contract.ContractDwMetastore.methods
        .walletToEvolution(account)
        .call({ from: account })
        .then(result => {
          let tempDaysClaim = parseInt(result)
          if (tempDaysClaim < 7) {
            setDaysClaim({
              msg: `${tempDaysClaim}/7 days to claim`,
              active: false,
            })
          } else {
            let calculateCommision = 16 - (tempDaysClaim - 7) * 2
            setDaysClaim({
              msg: `Claim with ${
                calculateCommision > 0 ? calculateCommision : 0
              }% of Commission`,
              active: true,
              commision: calculateCommision,
            })
          }
        })
        .catch(err => console.log(err))
  }

  return {
    daysClaim,
    form,
    step,
    setStep,
    handleUpdateAdd,
    handleUpdateRest,
    handleUpdateAll,
    price,
    handleAllPeach,
    inventoryFarm,
    handleClaimPeach,
    loading,
  }
}
