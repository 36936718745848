import React, { useState } from "react";
import { useEffect } from "react";

import arrowIcon from "../../assets/img/claimIcons/arrowIcon.webp";
import peachCoin from "../../assets/vector/peachCoin.svg";

export default function RowClaim({ elementIcon, price, material, name, handleUpdateRest, handleUpdateAdd, handleUpdateAll, inventoryFarm }) {

    return (



        <div className="flex justify-center text-center items-center ">

            <div className=" flex bg-white w-[300px] justify-between border rounded-xl  cursor-pointer mt-4">
                <button className="bg-secondaryDark px-2 py-1 text-white font-bold rounded-xl mr-4 duration-200 ease-in-out hover:bg-teal-500"
                    onClick={() => { handleUpdateAll(name) }}
                >Select All</button>
                <button className="px-3  font-bold hover:bg-gray-300 hover:rounded-l-xl " onClick={() => { handleUpdateRest(name) }}>-</button>

                <div className="flex justify-center text-3 font-bold items-center  ">
                    <span className=" ml-3 p-0">{material}/{inventoryFarm[name]} </span>
                    <img className=" mx-2 w-10 p-0" src={elementIcon} />

                </div>
                <button className="px-3 font-bold hover:bg-gray-300 hover:rounded-r-xl " onClick={() => { handleUpdateAdd(name) }}>+</button>



            </div>
            <div className="flex items-center w-[140px]">

                <img className="h-[1rem] mx-2 lg:mx-6 text-center mt-4 " src={arrowIcon} />
                <h2 className="text-3 font-300 text-white mr-2 mt-4">{price[name] * material}</h2>
                <img className="w-10 mr-3 mt-4" src={peachCoin} />

            </div>
        </div>

    )
}
