import React from "react";

import discordIcon from "../../assets/vector/discord.svg";
import facebookIcon from "../../assets/vector/facebook.svg";
import telegramIcon from "../../assets/vector/telegram.svg";
import instagramIcon from "../../assets/vector/instagram.svg";
import twitterIcon from "../../assets/vector/twitter.svg";

import { Link } from "gatsby";

const footer = () => {
    return (
        <div className="flex flex-col pb-5">
            <div className="grid grid-cols-5 gap-x-2 px-10 w-full h-12 justify-evenly">
                
                <a target="_blank" href="https://discord.gg/vC5CUqJU" >  <img alt="discord icon" src={discordIcon} className="" /> </a>
                <a target="_blank" href="https://www.facebook.com/darlingwaifuofficial" >  <img alt="facebook icon" src={facebookIcon} className="" /> </a>
                <a target="_blank" href="https://t.me/+LGSx_9Db3uI1ZTIx" >  <img alt="" src={telegramIcon} className="" /> </a>
                <a target="_blank" href="https://www.instagram.com/darlingwaifunft/" >  <img alt="instagram icon" src={instagramIcon} className="" /> </a>
                <a target="_blank" href="https://twitter.com/DarlingWaifuNFT" >  <img alt="twitter icon" src={twitterIcon} className="" /> </a>

            </div>

            <span className="footer-text cursor-pointer" > T&amp;C and privacy policy </span>

        </div>
    )
}
export default footer;