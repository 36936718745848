import React from "react"

import Button from "./button"
import RowClaim from "../elements/RowClaim"
import ClaimSvg from "../../assets/vector/claim.svg"
import woolIcon from "../../assets/img/claimIcons/woolIcon.svg"
import flaxIcon from "../../assets/img/claimIcons/flaxIcon.svg"
import silkIcon from "../../assets/img/claimIcons/silkIcon.svg"
import leaveIcon from "../../assets/img/claimIcons/leaveIcon.svg"
import cottonIcon from "../../assets/img/claimIcons/cottonIcon.svg"
import leatherIcon from "../../assets/img/claimIcons/leatherIcon.svg"
import backgroundOracle from "../../assets/img/claimIcons/backgroundOracle.webp"
import loadingIcon from "../../assets/img/loaders/dots.webp"

import successImage from "../../assets/img/successTransaction.webp"
import UserContext from "../../context/userContext/UserContext"
import useClaim from "../../hooks/useClaim"
import Loader from "./ModalX/loader"

export default function Claim({ setModal }) {
  const {
    daysClaim,
    form,
    step,
    setStep,
    handleUpdateAdd,
    handleUpdateRest,
    handleUpdateAll,
    price,
    handleAllPeach,
    inventoryFarm,
    handleClaimPeach,
    loading,
  } = useClaim()

  return (
    <>
      <h3 className="text-center text-white text-4xl font-bold">
        {" "}
        {daysClaim.msg}{" "}
      </h3>
      {step === 1 && (
        <div className="block md:flex justify-center w-[90vw] md:w-[56rem] rounded-md py-5 ">
          <div className="">
            <RowClaim
              inventoryFarm={inventoryFarm}
              name={"cotton"}
              handleUpdateAdd={handleUpdateAdd}
              handleUpdateRest={handleUpdateRest}
              handleUpdateAll={handleUpdateAll}
              material={form.cotton}
              elementIcon={cottonIcon}
              price={price}
            />
            <RowClaim
              inventoryFarm={inventoryFarm}
              name={"wool"}
              handleUpdateAdd={handleUpdateAdd}
              handleUpdateRest={handleUpdateRest}
              handleUpdateAll={handleUpdateAll}
              material={form.wool}
              elementIcon={woolIcon}
              price={price}
            />
            <RowClaim
              inventoryFarm={inventoryFarm}
              name={"leather"}
              handleUpdateAdd={handleUpdateAdd}
              handleUpdateRest={handleUpdateRest}
              handleUpdateAll={handleUpdateAll}
              material={form.leather}
              elementIcon={leatherIcon}
              price={price}
            />
            <RowClaim
              inventoryFarm={inventoryFarm}
              name={"leave"}
              handleUpdateAdd={handleUpdateAdd}
              handleUpdateRest={handleUpdateRest}
              handleUpdateAll={handleUpdateAll}
              material={form.leave}
              elementIcon={leaveIcon}
              price={price}
            />
            <RowClaim
              inventoryFarm={inventoryFarm}
              name={"flax"}
              handleUpdateAdd={handleUpdateAdd}
              handleUpdateRest={handleUpdateRest}
              handleUpdateAll={handleUpdateAll}
              material={form.flax}
              elementIcon={flaxIcon}
              price={price}
            />
            <RowClaim
              inventoryFarm={inventoryFarm}
              name={"silk"}
              handleUpdateAdd={handleUpdateAdd}
              handleUpdateRest={handleUpdateRest}
              handleUpdateAll={handleUpdateAll}
              material={form.silk}
              elementIcon={silkIcon}
              price={price}
            />
          </div>
          <div className="ml-0 md:ml-6">
            <img
              src={backgroundOracle}
              className=" hidden md:block w-[16rem] rounded-xl h-[19rem] mt-5"
            ></img>
            {daysClaim.active && (
              <div className="mt-8 md:mt-3 flex items-center justify-center  ">
                <Button
                  className="h-[5rem] w-[7rem]"
                  handleClick={() => {
                    setStep(2)
                  }}
                >
                  <span className="font-bold text-sm self-center ">
                    Claim <span className="text-primaryDark">$PEACH</span>
                  </span>
                </Button>
              </div>
            )}
          </div>
        </div>
      )}

      {step === 2 && (
        <div
          className={`flex flex-row text-white justify-center  ${
            !loading && ""
          } rounded-lg p-4`}
        >
          {loading ? (
            <div className="min-h-[40vh]">
              <Loader />
            </div>
          ) : (
            <>
              <div className="flex flex-col w-72 text-left">
                <span className="font-bold text-xl mt-0 md:mt-0 text-center">
                  You will receive {handleAllPeach()} $PEACH
                </span>

                <img
                  src={backgroundOracle}
                  className="m-auto mt-3 w-[13rem] h-hexyf "
                  alt="oracle"
                />

                {/*        <span className="py-4 mb-6 md:mb-0">If you claim now, your next comission will be set to <span className="text-primary">50%</span></span> */}
                {/* <span className="mb-24">Be sure to save some materials to take care of your Farmer Waifus</span> */}

                <div className="flex">
                  <button
                    onClick={() => {
                      setStep(1)
                    }}
                    className="absolute bottom-5  bg-primaryDark px-2 py-1 rounded-lg w-max hover:scale-110"
                  >
                    Cancel
                  </button>
                  <div className=" absolute right-5 bottom-5 w-44">
                    <Button handleClick={handleClaimPeach}>
                      <span className="font-bold text-sm text-black">
                        CLAIM {handleAllPeach()}{" "}
                        <span className="text-primaryDark">$PEACH</span>
                      </span>
                    </Button>
                  </div>
                </div>
              </div>
              {/*   <div className="hidden  md:flex flex-col w-72 justify-center items-center text-center">
                            <div className="w-44">
                                <Button handleClick={handleClaimPeach}>
                                    <span className="font-bold text-sm text-black">Claim {handleAllPeach()} <span className="text-primaryDark">$PEACH</span></span>
                                </Button>
                            </div>
                        </div> 
                         */}
            </>
          )}
        </div>
      )}

      {step === 3 && (
        <div className="inline-flex flex-col text-white justify-center text-center">
          <img
            src={successImage}
            className="h-60 mx-auto  mb-6"
            alt="oracle-success"
          />
          <span className="font-bold text-3xl mt-6">Succesful Transaction</span>
          <span className="py-3 text-xl">
            Your next commission has been set to{" "}
            <span className="text-primary">30%</span> and the anti-FUD was
            reseted{" "}
          </span>
          <button
            onClick={() => {
              setModal(false)
              setStep(1)
            }}
            className="bg-primaryDark px-2 py-1 rounded-lg w-max hover:scale-110"
          >
            CLOSE
          </button>
        </div>
      )}
    </>
  )
}
