import React, { useState } from "react";
import { Helmet } from "react-helmet";
import gameIcon from "../../assets/vector/leftBarIcons/gameIcon.svg";
import maintenanceIcon from "../../assets/vector/leftBarIcons/maintenanceIcon.svg";
import marketIcon from "../../assets/vector/leftBarIcons/marketIcon.svg";
import settingsIcon from "../../assets/vector/leftBarIcons/settingsIcon.svg";

import favicon from "../../assets/vector/isologo.svg";
import Claim from "../../assets/vector/claim.svg";
import OptionList from "../elements/optionList";
import Footer from '../elements/footer';
import { Link } from "gatsby";

import Button from "../elements/button";
import TractorIcon from "../../assets/vector/leftBarIcons/tractorIcon";
import StarIcon from "../../assets/vector/leftBarIcons/starIcon";
import MintIcon from "../../assets/vector/leftBarIcons/mintIcon";
import HarvestIcon from "../../assets/vector/leftBarIcons/harvestIcon";
import HospitalIcon from "../../assets/vector/leftBarIcons/hospitalIcon";
import MechanicIcon from "../../assets/vector/leftBarIcons/mechanicIcon";
import CrewsIcon from "../../assets/vector/leftBarIcons/crewsIcon";
import ClaimPeachIcon from "../../assets/vector/leftBarIcons/claimPeachIcon";

import Icons from "../../assets/vector/icons";
import ModalClaimPeach from "../ModalClaimPeach/ModalClaimPeach";

const LeftBar = ({ menuOpen, changeOpenMenu, changeOpenOracle }) => {
    const [modal, setModal] = useState(false)
    return (
        <>
            <ModalClaimPeach modal={modal} setModal={setModal} />
            <aside className={`z-50 top-0 left-0 fixed h-full w-56 bg-backgroundOp ${menuOpen ? "translate-x-0" : "-translate-x-full"} lg:translate-x-0 ease-in-out duration-300 `} style={{ overflowY: "auto" }}>

                <div className="w-full p-4" >
                    <Link to="/">
                        <img alt="favicon" className="m-auto w-48 h-12" src={favicon} />
                    </Link>
                </div>

                {/* <div className="xl:hidden flex h-100 m-auto items-center justify-center text-background text-xs lg:text-sm" onClick={() => { setModal(true) }}>
                    <Button>
                        <div className="hidden xl:inline-block">
                            <img src={Claim} className="w-8 mr-3 cursor-pointer inline" alt="claim-icon" />
                        </div>
                        Claim $PEACH
                    </Button>
                </div> */}
                <div className="xl:hidden flex h-100 m-auto items-center justify-center text-background text-xs lg:text-sm mt-5 xl:mt-0" onClick={() => {
                    changeOpenMenu();
                    changeOpenOracle()
                }}>
                    <Button>
                        <div className="hidden xl:inline-block">
                            <img src={Claim} className="w-8 mr-3 cursor-pointer inline" alt="claim-icon" />
                        </div>
                        Claim Harvest
                    </Button>
                </div>

                <OptionList titleIcon={gameIcon} title={'Play to Business'} options={[
                    { icon: <TractorIcon />, text: "Tractor", link: "/inventoryTractor" }, { icon: <StarIcon />, text: "Farmer Waifu", link: "/inventoryWaifu" }, { icon: <MintIcon />, text: "Mint", link: "/mint" },/*  { icon: <Icons.group />, text: "Farm House", link: "/farmHouse" }, */ { icon: <HarvestIcon />, text: "Harvesting", link: "/harvesting" }, { icon: <CrewsIcon/>, text: "Crews", link: "/crews" }
                ]} />
                <OptionList titleIcon={maintenanceIcon} title={'Maintenance'} options={[
                    { icon: <HospitalIcon />, text: "Hospital", link: "/hospital" }, { icon: <MechanicIcon />, text: "Mechanic", link: "/mechanic" }
                ]} />
                <OptionList titleIcon={marketIcon} title={'Marketplace'} options={[{ icon: <TractorIcon />, text: "Tractor", link: "/marketTractor" }, { icon: <StarIcon />, text: "Farmer Waifu", link: "/marketWaifu" },]} />


                {/*  <OptionList titleIcon={settingsIcon} title={'Settings'} options={[{ icon: <ClaimPeachIcon />, text: "Claim Peach", link: "/claimPeach" },]} /> */}

                <div className="bg-primarypeach mx-6 px-2 py-4 my-4 rounded">
                    <h3 className="text-primaryDark text-base font-bold">Social Media</h3>
                    <p className=" text-background text-sm">Join to our Community</p>
                </div>

                <Footer />

            </aside>
            <div onClick={changeOpenMenu} className={`  ${menuOpen ? "translate-x-0" : "-translate-x-full"} block lg:hidden fixed top-0 left-0 w-full h-full bg-[#00000052] z-40`}>

            </div>
        </>
    )
}


export default LeftBar;
