import React, { useState, useEffect, useContext } from "react";
import Modal from "../../components/elements/Modal"
import Loader from '../../components/elements/ModalX/loader'
import UserContext from '../../context/userContext/UserContext';

import peachIcon from "../../assets/img/pechicon2.svg"
import successTransaction from "../../assets/img/successTransaction.webp"


export default function ModalClaimPeach({ modal, setModal }) {

    const [step, setStep] = useState(1)
    const [secondLeftClaim, setSecondLeftClaim] = useState("")
    const { contract, account } = useContext(UserContext)


    useEffect(() => {

        let intervalId;
        let getMintTime = async () => {

            if (modal && contract && account) {



                let secondLeft = await contract.contractPeach.methods.getMintableTime().call({ from: account })
                console.log("testing", secondLeft)
                let timeNow = new Date()
                secondLeft = secondLeft - (timeNow.getTime() / 1000)
                if (secondLeft < 0) return setSecondLeftClaim(null);
                setSecondLeftClaim(new Date((secondLeft || 0) * 1000).toISOString().substr((secondLeft || 0) < 3600 ? 14 : 11, (secondLeft || 0) < 3600 ? 5 : 8))

                intervalId = setInterval(() => {
                    secondLeft -= 1
                    if (secondLeft < 1) { setSecondLeftClaim(null); clearInterval(intervalId) }
                    else setSecondLeftClaim(new Date((secondLeft || 0) * 1000).toISOString().substr((secondLeft || 0) < 3600 ? 14 : 11, (secondLeft || 0) < 3600 ? 5 : 8))

                }, 1000)
                return () => { clearInterval(intervalId) }
            }
            else if (!modal) { setStep(1) }

        }

        getMintTime()

        return () => { clearInterval(intervalId) }

    }, [modal])



    const handleSubmit = async () => {

        if (contract && account) {
            setStep(step + 1)
            let result = await contract.contractPeach.methods.faucet().send({ from: account }).then(() => { setStep(step + 2) }).catch((err) => { setStep(1) })

        }
    }



    return (

        <Modal screen={true} modal={modal} setModal={setModal}>



            {step === 1 && <div className="w-[50vw]  p-5 rounded-lg">
                <h3 className=" text-center text-white text-xl flex flex-row justify-center items-center mb-5 font-bold">CLAIM <span className="text-[#f7b0bb] font-bold ml-2"> $PEACH</span><img className="w-7 mx-2" src={peachIcon} alt="peachIcon" /></h3>

                <span className="text-center text-white text-xl flex flex-row justify-center items-center mb-5">Get 1000 tokens every 24 hours </span>
                <span className="text-center text-white text-4xl font-bold flex flex-row justify-center items-center mb-5">{secondLeftClaim}</span>

                <div className="text-center">
                    <button onClick={() => { setModal(false) }} className="rounded-md font-bold w-20 text-white  p-2 mr-0 md:mr-4 bg-[#AA2E74]">CANCEL </button>
                    {secondLeftClaim === null && <button onClick={handleSubmit} className={`rounded-md text-white p-2 mt-4 md:mt-0 w-20 font-bold bg-[#D9A900]`}>CLAIM </button>}
                </div>



            </div>}


            {step === 2 && <div className="p-10">

                <Loader />
            </div>}


            {step === 3 && <div>

                <img src={successTransaction} className="h-60 mx-auto" alt="successTransaction" />
                <h3 className="text-center text-white text-xl  mb-3 "> <strong>Your 1000 $PEACH have been allocated to your wallet</strong> </h3>
                <div> <button onClick={() => { setModal(false) }} className="rounded-md font-bold w-20 text-white p-2  bg-[#AA2E74]">Close </button></div>

            </div>}



        </Modal>

    )
}
