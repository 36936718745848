import React from "react"

export default function mechanicIcon() {
    return (
        <svg
            viewBox="0 0 40 40"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g clipPath="url(#clip0_502_138907)">
                <path
                    d="M28.3295 20.8654L26.4823 20.2334C26.3965 20.204 26.3015 20.2227 26.2332 20.2823L24.7622 21.5661C24.6939 21.6257 24.6626 21.7172 24.6801 21.8062L25.0563 23.722C25.0738 23.811 25.1374 23.8839 25.2232 
                    23.9132L27.0705 24.5453C27.1563 24.5746 27.2512 24.556 27.3195 24.4963L28.7905 23.2126C28.8588 23.153 28.8902 23.0614 28.8727 22.9724L28.4964 21.0567C28.4789 20.9677 28.4153 20.8948 28.3295 20.8654Z"
                    fill="currentColor"
                />
                <path
                    d="M14.9829 22.2638L14.8767 21.1518C14.8682 21.0632 14.9061 20.9765 14.9769 20.9226L16.3358 19.8855C16.4331 19.8113 16.4647 19.6788 16.4114 19.5686L15.2588 17.188C15.2047 17.0762 15.0786 17.019 14.9589 
                    17.0519L13.3106 17.5041C13.2305 17.526 13.1447 17.508 13.0802 17.4557L12.2735 16.8012C12.2008 16.7422 12.1658 16.6484 12.1821 16.5562L12.4945 14.7932C12.5173 14.6645 12.4402 14.5392 12.315 
                    14.5016L9.75839 13.7336C9.64524 13.6996 9.52347 13.7469 9.46302 13.8484L8.62927 15.2483C8.58396 15.3244 8.50275 15.3718 8.41423 15.374L7.33067 15.4003C7.24851 15.4022 7.17034 15.3649 7.12025 
                    15.2998L6.09259 13.9633C6.01817 13.8666 5.88605 13.8353 5.77618 13.8885L4.68453 14.417L3.59288 14.9455C3.48301 14.9987 3.42557 15.1217 3.45533 15.2401L3.86627 16.8752C3.8863 16.9549 3.8671 
                    17.0393 3.81458 17.1025L3.1219 17.9362C3.06531 18.0043 2.97771 18.0386 2.88994 18.0269L1.27476 17.8127C1.15764 17.7972 1.04497 17.8633 1.00145 17.9731L0.0181507 20.4549C-0.0299826 20.5764 0.0204882 
                    20.7147 0.135555 20.7766L1.71231 21.6251C1.79475 21.6694 1.84663 21.755 1.8478 21.8486L1.86083 22.8874C1.86188 22.9705 1.82279 23.049 1.75587 23.0982L0.378846 24.1106C0.278817 24.1842 0.245497 
                    24.3186 0.299597 24.4304L1.45212 26.8109C1.50547 26.9211 1.62901 26.9785 1.74763 26.9482L3.40395 26.5254C3.49017 26.5034 3.58165 26.5275 3.64588 26.5891L4.45229 27.3621C4.51429 27.4216 4.54258 
                    27.508 4.52774 27.5926L4.24665 29.1939C4.22467 29.3192 4.29737 29.4417 4.41782 29.4824L6.99094 30.3526C7.10786 30.3922 7.23641 30.3432 7.29738 30.2359L8.13422 28.7631C8.17704 28.6877 8.25492 28.6389 
                    8.34142 28.6333L9.35232 28.5675C9.43999 28.5618 9.52452 28.6011 9.57656 28.6719L10.5839 30.042C10.6574 30.142 10.7919 30.1753 10.9036 30.1212L12.0248 29.5784L13.1459 29.0356C13.2577 28.9815 13.3149 
                    28.8554 13.2821 28.7357L12.8321 27.0957C12.8089 27.011 12.8304 26.9203 12.8893 26.8551L13.5679 26.1029C13.626 26.0386 13.7125 26.0077 13.7982 26.0209L15.4726 26.278C15.5946 26.2967 15.7127 26.2262 
                    15.7542 26.11L16.6675 23.5519C16.7103 23.4321 16.6593 23.2991 16.5474 23.2387L15.1169 22.4659C15.0414 22.4251 14.9911 22.3493 14.9829 22.2638ZM9.90198 25.3765L6.45399 25.2066C6.36209 25.2021 6.27958 
                    25.1489 6.23755 25.067L4.6607 21.996C4.61867 21.9141 4.6235 21.8161 4.67337 21.7388L6.5445 18.8377C6.59437 18.7603 6.68171 18.7155 6.77362 18.72L10.2216 18.8899C10.3135 18.8944 10.396 18.9477 10.438 
                    19.0295L12.0149 22.1005C12.0569 22.1824 12.0521 22.2804 12.0022 22.3578L10.1311 25.2589C10.0812 25.3362 9.99388 25.381 9.90198 25.3765Z"
                    fill="currentColor"
                />
                <path
                    d="M24.9592 7.4668L24.5958 6.75155C24.5506 6.66263 24.5546 6.55669 24.6062 6.47136L25.2543 5.40008C25.3253 5.28267 25.3039 5.13149 25.203 5.03846L23.7501 3.69831C23.6478 3.60396 23.4928 3.59601 23.3814 
                    3.67941L22.3775 4.43099C22.3029 4.48679 22.2061 4.50322 22.1173 4.47511L21.4131 4.25209C21.3131 4.22041 21.2381 4.13695 21.2173 4.0341L20.9581 2.75198C20.9291 2.60843 20.7972 2.50936 20.6512 
                    2.52151L18.6636 2.68699C18.5316 2.69798 18.4241 2.79734 18.4027 2.92802L18.2102 4.10372C18.1941 4.20166 18.1289 4.28449 18.0375 4.32306L17.3205 4.62546C17.2356 4.66126 17.1388 4.65441 17.0598 
                    4.60702L15.9986 3.97035C15.8813 3.89996 15.7307 3.9216 15.6379 4.02217L15.0266 4.68491L14.4153 5.34766C14.3226 5.44824 14.3131 5.60011 14.3927 5.71137L15.1129 6.71774C15.1665 6.79265 15.1811 6.88863 
                    15.1523 6.97611L14.9087 7.71515C14.8776 7.8094 14.8003 7.88109 14.704 7.90496L13.5476 8.19164C13.4191 8.2235 13.3287 8.33873 13.3284 8.47115L13.3237 10.4656C13.3234 10.6121 13.4328 10.7356 13.5782 
                    10.753L14.877 10.908C14.9812 10.9204 15.0705 10.9884 15.1101 11.0855L15.3892 11.7694C15.4243 11.8556 15.4158 11.9535 15.3662 12.0323L14.698 13.0936C14.6238 13.2113 14.6442 13.3652 14.7465 13.4596L16.1995 
                    14.7997C16.3003 14.8927 16.4527 14.9019 16.564 14.8217L17.5796 14.0893C17.6605 14.0309 17.7657 14.0185 17.858 14.0564L18.6002 14.3609C18.6893 14.3975 18.7543 14.476 18.7735 14.5703L19.01 15.7302C19.0384 
                    15.8698 19.1645 15.9678 19.3069 15.9608L21.3352 15.8618C21.4734 15.8551 21.5874 15.7513 21.6069 15.6143L21.7823 14.3864C21.796 14.2902 21.8572 14.2074 21.9451 14.166L22.596 13.8597C22.6851 13.8178 22.7894 
                    13.8241 22.8727 13.8766L23.9257 14.5396C24.0435 14.6138 24.1974 14.5934 24.2917 14.4911L24.9198 13.8102L25.5478 13.1293C25.6422 13.027 25.6501 12.8719 25.5667 12.7605L24.8209 11.7644C24.7619 11.6856 24.7472 
                    11.5821 24.7818 11.4899L25.0346 10.8165C25.0688 10.7255 25.1464 10.6578 25.2412 10.6364L26.451 10.3626C26.5859 10.3321 26.6802 10.2101 26.6758 10.0719L26.6109 8.04216C26.6063 7.89972 26.4985 7.78191 26.3571 
                    7.7648L25.1819 7.62261C25.0864 7.61105 25.0028 7.55261 24.9592 7.4668ZM22.0668 11.3223L19.5442 12.1741C19.4465 12.2071 19.3386 12.1855 19.2611 12.1174L17.2621 10.3586C17.1847 10.2904 17.1495 10.1862 17.1698 
                    10.0851L17.6934 7.4745C17.7137 7.37338 17.7864 7.29076 17.8841 7.25777L20.4067 6.40596C20.5045 6.37297 20.6124 6.39461 20.6898 6.46274L22.6888 8.22151C22.7662 8.28964 22.8014 8.3939 22.7811 8.49501L22.2575 
                    11.1056C22.2372 11.2067 22.1645 11.2893 22.0668 11.3223Z"
                    fill="currentColor"
                />
                <path
                    d="M21.695 32.2064L21.439 31.7027C21.4126 31.6506 21.4149 31.5885 21.4451 31.5385L21.8974 30.7909C21.939 30.7221 21.9265 30.6334 21.8673 30.5789L20.8628 29.6523C20.8028 29.597 20.7119 29.5923 20.6466 29.6412L19.9466 
                    30.1653C19.9029 30.198 19.8461 30.2076 19.7941 30.1911L19.2958 30.0333C19.2372 30.0148 19.1932 29.9658 19.181 29.9055L18.9999 29.0097C18.9829 28.9256 18.9056 28.8675 18.82 28.8746L17.4456 28.989C17.3682 28.9955 17.3051 
                    29.0537 17.2926 29.1304L17.1581 29.9514C17.1487 30.0089 17.1105 30.0574 17.0569 30.08L16.5519 30.293C16.5021 30.314 16.4453 30.31 16.399 30.2822L15.6592 29.8384C15.5905 29.7971 15.5021 29.8098 15.4478 29.8687L15.0241 
                    30.3281L14.6004 30.7874C14.546 30.8464 14.5405 30.9354 14.5871 31.0007L15.0892 31.7023C15.1206 31.7462 15.1292 31.8024 15.1123 31.8537L14.9407 32.3743C14.9225 32.4295 14.8772 32.4716 14.8207 32.4856L14.0131 
                    32.6858C13.9378 32.7045 13.8848 32.772 13.8846 32.8497L13.8813 34.2288C13.8811 34.3147 13.9453 34.3871 14.0305 34.3973L14.938 34.5056C14.9991 34.5129 15.0514 34.5527 15.0747 34.6097L15.2721 35.0936C15.2927 35.1441 
                    15.2877 35.2015 15.2586 35.2477L14.7927 35.9877C14.7492 36.0567 14.7612 36.147 14.8212 36.2023L15.8257 37.1289C15.8849 37.1834 15.9742 37.1888 16.0395 37.1417L16.7482 36.6307C16.7956 36.5965 16.8573 36.5892 16.9114 
                    36.6114L17.4341 36.8258C17.4863 36.8473 17.5244 36.8933 17.5357 36.9486L17.7012 37.7602C17.7179 37.8421 17.7918 37.8995 17.8753 37.8955L19.2774 37.8271C19.3585 37.8231 19.4253 37.7622 19.4367 37.6819L19.5591 
                    36.8249C19.5672 36.7685 19.603 36.72 19.6546 36.6957L20.1154 36.4789C20.1676 36.4543 20.2288 36.458 20.2776 36.4888L21.0128 36.9516C21.0818 36.9951 21.172 36.9831 21.2274 36.9232L21.6626 36.4513L22.0978 35.9795C22.1531 
                    35.9195 22.1578 35.8286 22.1089 35.7633L21.5882 35.0678C21.5536 35.0216 21.545 34.961 21.5653 34.9069L21.7443 34.4302C21.7643 34.3768 21.8098 34.3371 21.8654 34.3246L22.7097 34.1335C22.7888 34.1156 22.8441 34.0441 
                    22.8415 33.963L22.7967 32.5599C22.794 32.4764 22.7308 32.4073 22.6478 32.3973L21.8255 32.2978C21.7695 32.291 21.7205 32.2568 21.695 32.2064ZM19.7616 34.7774L18.0421 35.358C17.9848 35.3774 17.9215 35.3647 17.8761 
                    35.3247L16.5135 34.1259C16.4681 34.086 16.4475 34.0248 16.4594 33.9656L16.8163 32.1861C16.8282 32.1268 16.8708 32.0784 16.9281 32.0591L18.6476 31.4784C18.7049 31.4591 18.7682 31.4718 18.8136 31.5117L20.1761 
                    32.7106C20.2215 32.7505 20.2422 32.8116 20.2303 32.8709L19.8734 34.6504C19.8615 34.7096 19.8189 34.7581 19.7616 34.7774Z"
                    fill="currentColor"
                />
                <path
                    d="M30.7731 17.0517L30.9456 15.6149C30.9618 15.4795 30.8817 15.3509 30.7529 15.3059L28.348 14.4655C28.2172 14.4198 28.0724 14.4728 28.0019 14.592L27.168 16.0034L27.1153 16.0088C26.6888 15.9997 26.2661 16.0331 25.8528 
                    16.1063C24.8133 16.2906 23.8332 16.7264 23.0016 17.3712C22.8187 17.513 22.8716 17.8029 23.0914 17.8753L29.5023 19.9853C29.6004 20.0176 29.674 20.0995 29.6956 20.2005L30.3976 23.48C30.4194 23.5815 30.3853 23.6869 30.3082 
                    23.7565L27.8217 26.0019C27.7437 26.0723 27.6338 26.0952 27.5342 26.0617L21.1151 23.9017C20.8945 23.8275 20.6782 24.0304 20.7417 24.2543C21.0064 25.1867 21.4778 26.0528 22.1219 26.7828C22.3994 27.0972 22.7089 27.3863 
                    23.0478 27.6446L23.0866 27.6825L22.8817 29.2381C22.8637 29.3746 22.944 29.5049 23.074 29.5504L25.4827 30.3921C25.6105 30.4368 25.7523 30.3872 25.8244 30.2727L26.5599 29.1051C26.615 29.0175 26.7126 28.9674 26.8161 
                    28.9701C27.1662 28.979 27.5138 28.9594 27.8559 28.9124C27.8659 28.911 27.8759 28.9102 27.8859 28.91L27.8863 28.91C27.9832 28.9081 28.0746 28.9546 28.1301 29.0341L29.0165 30.3024C29.0987 30.42 29.2544 30.46 29.3831 
                    30.3967L31.7238 29.2439C31.8487 29.1824 31.9125 29.0409 31.8757 28.9065L31.5601 27.7527C31.4983 27.5271 31.715 27.3247 31.9359 27.4016L37.2452 29.2496C37.8463 29.4589 38.5031 29.1412 38.7123 28.5402L39.9357 
                    25.0253C40.145 24.4243 39.8273 23.7674 39.2263 23.5582L33.9401 21.7183C33.7188 21.6412 33.675 21.3475 33.8641 21.2092L34.8064 20.5202C34.9188 20.438 34.9571 20.2876 34.8977 20.1616L33.7845 17.8019C33.7233 17.6721 
                    33.5766 17.6065 33.439 17.6473L31.9555 18.0874C31.8626 18.115 31.7621 18.0944 31.6875 18.0326C31.6796 18.0261 31.6721 18.0191 31.665 18.0116C31.4272 17.7623 31.1681 17.5309 30.8894 17.3202C30.8059 17.2571 30.7606 
                    17.1556 30.7731 17.0517Z"
                    fill="currentColor"
                />
            </g>
            <defs>
                <clipPath id="clip0_502_138907">
                    <rect width="40" height="40" fill="white" />
                </clipPath>
            </defs>
        </svg>
    )
}
