import React from "react";

export default function crewsIcon()
{
    return (
        <svg version="1.0" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 viewBox="0 0 96 96" style="enable-background:new 0 0 96 96;" xmlSpace="preserve">
<g>
	<g>
		<path d="M95.711693,67.390663c-0.511032-1.858192-1.029343-3.714417-1.551224-5.569588
			c-0.281013-0.998932-0.936043-1.619339-1.942017-1.871613c-1.083054-0.271606-2.162437-0.558735-3.249657-0.812366
			c-0.811943-0.189404-1.333572-0.664898-1.639-1.433914c-0.385117-0.969715-0.784454-1.933765-1.183037-2.898144
			c0.405266-0.26128,0.630257-0.710445,0.649048-1.327362c0.028633-0.939331,0.027428-1.880833,0.000603-2.820251
			c-0.028793-1.005123-0.586494-1.582901-1.580566-1.601791c-0.340248-0.006458-0.68055-0.01157-1.020905-0.015728
			c-0.051849-1.2038-0.086014-1.979534-0.086014-1.979534c0-0.155392-0.161133-0.575542-0.257195-0.726574
			c-1.260414-1.981987-3.075317-2.975231-5.415718-2.977955c-1.649445-0.001923-3.298897-0.00383-4.948341-0.005287
			c-0.023918-0.403107-0.047485-0.806236-0.072304-1.20929c-0.053864-0.875332-0.119278-1.749989-0.167915-2.625595
			c-0.073402-1.321423,0.344116-2.497398,1.09938-3.562351c1.095718-1.545006,2.207359-3.078732,3.299706-4.626072
			c0.712105-1.008654,0.21022-2.276489-0.966789-2.446526c-0.630157-0.091042-1.112595,0.188564-1.481888,0.690409
			c-0.852036,1.157879-1.716728,2.30665-2.556747,3.473181c-0.483498,0.671471-1.105209,1.005604-1.93885,0.991558
			c-1.116219-0.018814-2.233086-0.003216-3.349648-0.003578c-1.037132-0.00034-1.82354,0.441868-2.33934,1.342285
			c-0.958046,1.672184-1.91283,3.346287-2.861027,5.024052c-0.452847,0.801197-0.202232,1.683311,0.58971,2.139889
			c0.748226,0.431355,1.786011,0.166973,2.213783-0.593452c0.238106-0.423252,0.419991-0.878086,0.627243-1.318684
			c0.040901,0.005112,0.081749,0.010239,0.122597,0.015362c-0.115112,0.903229-0.230469,1.806423-0.345123,2.709717
			c-2.688591,0.00304-5.377178,0.008766-8.065666,0.022331c-0.763805,0.003845-1.040749-0.251945-1.259209-0.941605
			c-0.976685-3.0835-4.029991-12.277536-4.530117-13.80772c1.550671-0.466562,2.269104-1.163054,2.383007-2.264606
			c0.162437-1.57122-0.978294-2.760626-2.674923-2.761694c-7.124249-0.004509-28.368572-0.013327-31.866283,0.004372
			c-1.439129,0.007273-2.512182,1.106743-2.499069,2.487778c0.012762,1.341278,1.080992,2.398544,2.475906,2.442066
			c0.379843,0.011847,0.760387,0.001797,1.184546,0.001797c0.021955,0.194632,0.047178,12.639708,0.018841,18.786493
			c-0.001255,0.269493-0.267195,0.654171-0.515852,0.787506c-1.805054,0.968044-3.436665,2.158768-4.876749,3.613056
			c-0.870522,0.879078-1.000954,1.599483-0.403255,2.180351c0.608953,0.591793,1.238506,0.440624,2.16922-0.465446
			c4.157259-4.047176,9.138063-5.795616,14.868704-4.926891c9.705616,1.471298,16.060738,10.409981,14.433395,20.091393
			c-0.234688,1.396309,0.202633,1.916679,1.621059,1.916962c6.301258,0.001228,12.602516,0.000526,18.90377,0.000534
			c0.269913,0,0.539818,0,0.785408,0c0.678497-4.102455,2.72979-7.086964,6.402901-8.815834
			c3.692108-1.737843,7.310555-1.320557,10.786659,0.731331c0-0.475792-0.037781-1.689217-0.0942-3.237434
			c0.119324,0.304363,0.239906,0.608261,0.358383,0.912964c0.473198,1.216827,0.591721,2.459087,0.198715,3.722839
			c-0.180931,0.58181-0.368942,1.161407-0.556351,1.741234c-1.56353-1.525909-3.702148-2.464691-6.070137-2.462593
			c-4.701294,0.004158-8.584679,3.884148-8.596786,8.589317c-0.012413,4.803352,3.84655,8.683662,8.645622,8.693459
			c4.71125,0.009621,8.613976-3.852425,8.638046-8.547966c0.002312-0.452736-0.029648-0.897278-0.093407-1.331223
			c0.164047-0.177681,0.382156-0.264381,0.681-0.183487c0.427879,0.115822,0.554741,0.451393,0.511688,0.871628
			c-0.034424,0.336227-0.451645,5.593742-0.645081,7.884445c-0.035828,0.424072,0.109177,0.672287,0.543083,0.762917
			c0.496407,0.103691,0.985329,0.243225,1.477669,0.36644c0.691353,0.172974,0.859818,0.070663,0.945984-0.626945
			c0.381447-3.087708,0.756165-6.176239,1.131836-9.264664c0.144455-1.187607,0.284988-2.375679,0.427322-3.563545
			c0.040848,0.004974,0.081749,0.009964,0.122597,0.014961c0.093704,0.477806,0.15937,0.963257,0.287193,1.431747
			c0.229713,0.841705,1.171883,1.350899,2.002609,1.114761C95.497856,69.07254,95.955727,68.278023,95.711693,67.390663z
			 M32.89592,41.808628c-0.003716,1.106388-0.436615,1.539352-1.543333,1.542301
			c-2.263527,0.00602-4.527103,0.006081-6.79063-0.000034c-1.106617-0.003002-1.539215-0.435238-1.540571-1.543549
			c-0.004623-3.909775-0.004673-7.819546,0.00005-11.729319c0.001307-1.108789,0.432447-1.537971,1.541878-1.540859
			c2.263577-0.00588,4.527103-0.005867,6.79063,0c1.10722,0.002876,1.538361,0.434292,1.542027,1.542152
			c0.006432,1.954866,0.001759,3.909761,0.001759,5.864653S32.902451,39.85376,32.89592,41.808628z M48.440804,54.302502
			c0.01186,0.001408,0.021706,0.003216,0.029747,0.004826c0.001606,0.009243,0.003014,0.020901,0.004219,0.03537
			c0.007236,0.09716,0.016933,0.194431,0.025421,0.291637c-0.06818-0.11792-0.137314-0.234737-0.207001-0.350914
			C48.342426,54.289692,48.391567,54.296272,48.440804,54.302502z M53.888123,54.07613
			c-0.001007,1.023838-0.296993,1.472252-1.117271,1.589848c-0.02512-0.589825-0.086166-1.18256-0.19841-1.77467
			c-0.415215-2.190022-1.224136-3.093399-3.20615-3.57996c-0.87846-0.215847-1.778824-0.33382-2.649647-0.447971
			c-0.185902-0.024319-0.371803-0.048637-0.557304-0.073959l-0.090839-0.012058
			c-0.017887-0.002415-0.036175-0.004826-0.054264-0.007236c-0.009647-0.03899-0.019093-0.078579-0.02673-0.109932
			c-0.433502-1.805355-0.62463-3.629704-0.81274-5.656532c-0.008041-0.085514-0.015678-0.171028-0.023315-0.256844
			l0.794254,0.424255c0.853336,0.456112,1.706875,0.912224,2.560616,1.367634l0.026932,0.01437
			c0.138874,0.074257,0.296436,0.158367,0.469276,0.225693c0.499222,0.194141,1.061749,0.170528,1.543083-0.065517
			c0.483746-0.23695,0.849319-0.669548,1.002861-1.186653c0.275738-0.928303-0.136662-1.817715-1.076015-2.321159
			l-1.365021-0.733154c-1.525398-0.819977-3.102848-1.667786-4.667637-2.480629
			c-0.455811-0.236847-0.932117-0.405468-1.392754-0.568558c-0.158768-0.056271-0.317741-0.112446-0.474903-0.171432
			c-0.196152-0.073353-0.424255-0.104004-0.645126-0.085514c-1.179924,0.097775-2.086117,0.400543-2.85183,0.953022
			c-1.062351,0.766315-1.578255,1.882629-1.942623,2.873634c-0.272522,0.740692-0.542229,1.4636-0.844093,2.163391
			c-0.046623,0.108063-0.0825,0.216778-0.111839,0.325378c-0.546051-0.257999-0.81591-0.688801-0.814251-1.35659
			c0.010803-4.369877,0.001556-8.739807,0.007938-13.109713c0.001507-1.041525,0.454758-1.473934,1.524895-1.477991
			c1.799328-0.006809,8.997082,0.015877,10.796104-0.009861c0.815506-0.011656,1.296036,0.339622,1.545948,1.101604
			c1.4953,4.558983,3.003414,9.113773,4.495399,13.673836c0.101543,0.310356,0.148872,0.652138,0.149979,0.979549
			C53.893448,47.546955,53.891285,50.811546,53.888123,54.07613z M62.531689,52.042519
			c-0.000153,0.578655,0.008087,1.157513,0.004318,1.736069c-0.001255,0.19286-0.003868,0.385681-0.008591,0.578453
			c-0.019493,0.796852-0.504448,1.319351-1.199917,1.33736c-0.72356,0.01873-1.250366-0.522396-1.258858-1.353928
			c-0.015976-1.568645-0.016327-3.137703,0.000706-4.706322c0.00869-0.798473,0.500072-1.318081,1.197659-1.336292
			c0.724461-0.018894,1.242424,0.520512,1.261013,1.352947c0.004471,0.199215,0.006935,0.398472,0.008141,0.597763
			C62.539726,50.846443,62.531837,51.444569,62.531689,52.042519z M67.465668,54.35585
			c-0.008743,0.797028-0.502487,1.319775-1.197762,1.338539c-0.723457,0.01952-1.242874-0.521706-1.260963-1.352711
			c-0.017387-0.796875-0.003769-1.594444-0.003616-2.391697c0.000145-0.771553-0.014221-1.543434,0.00412-2.314548
			c0.019043-0.799,0.500023-1.318619,1.197502-1.337574c0.724312-0.019672,1.252373,0.519947,1.261215,1.351665
			C67.48275,51.218159,67.482849,52.787228,67.465668,54.35585z M72.403206,54.3871
			c-0.009949,0.788475-0.528259,1.307228-1.227402,1.307289c-0.699242,0.000065-1.206299-0.518867-1.227798-1.306889
			c-0.010857-0.398331-0.012062-0.796925-0.010551-1.195599c0.001503-0.39867,0.005775-0.797428,0.005775-1.196049
			c0-0.398632-0.004272-0.79739-0.005775-1.196064c-0.001564-0.39867-0.000359-0.797264,0.010597-1.195595
			c0.021706-0.790699,0.525345-1.305897,1.227303-1.306335c0.7005-0.000443,1.217804,0.516315,1.227852,1.305832
			C72.423454,51.19796,72.423309,52.792831,72.403206,54.3871z M78.561073,76.690826
			c-2.719841-0.006119-4.930817-2.246849-4.914436-4.980705c0.016083-2.691757,2.227402-4.891663,4.921066-4.895691
			c2.733253-0.004097,4.960052,2.216362,4.954224,4.940102C83.515999,74.485413,81.293472,76.696968,78.561073,76.690826z" fill="currentColor"/>
		<path d="M90.935532,58.112995c1.880165,0.451122,3.766411-0.719238,4.223526-2.620533
			c0.455513-1.894691-0.693909-3.787254-2.581161-4.250061c-1.903633-0.466827-3.82827,0.704906-4.285637,2.609116
			C87.835449,55.753437,89.012856,57.651657,90.935532,58.112995z" fill="currentColor"/>
		<path d="M29.339674,50.763504c-7.753599-0.037647-14.188153,5.851036-14.893675,13.334137
			c-0.568708-0.999687-1.135657-2.000378-1.70743-2.998329c-0.515852-0.900402-1.302216-1.342609-2.339345-1.34227
			c-1.116614,0.000351-2.233431-0.015236-3.349694,0.003578c-0.833592,0.01403-1.455307-0.320087-1.938852-0.991558
			c-0.840024-1.166531-1.704667-2.315304-2.556749-3.473179c-0.369291-0.501846-0.85173-0.781452-1.481886-0.690411
			c-1.176958,0.170036-1.678841,1.437859-0.966788,2.446514c1.092347,1.54734,2.203988,3.081078,3.299702,4.626083
			c0.755262,1.064938,1.172838,2.240917,1.099432,3.562347c-0.048636,0.875595-0.114103,1.750267-0.167964,2.625595
			c-0.084108,1.365891-0.159825,2.732315-0.247902,4.097954c-0.157865,2.447479-0.325378,4.894333-0.481736,7.341904
			c-0.036376,0.569618,0.115209,0.715088,0.702105,0.720161c0.507511,0.004372,1.016479-0.02272,1.522232,0.00753
			c0.51133,0.030556,0.732854-0.194374,0.807516-0.68367c0.196302-1.286888,1.058081-6.422508,1.271617-7.706635
			c0.070743-0.425362,0.267798-0.753479,0.745214-0.744736c0.443199,0.008125,0.647943,0.302696,0.708536,0.720757
			c0.048535,0.334496,0.924333,5.535309,1.294628,7.804131c0.068583,0.420029,0.269608,0.625473,0.712505,0.607689
			c0.506758-0.020348,1.014922-0.004082,1.522483-0.004509c0.712656-0.000595,0.851128-0.140862,0.76481-0.838455
			c-0.382053-3.087624-0.770839-6.174416-1.158719-9.261337c-0.149123-1.187035-0.302216-2.37355-0.4535-3.560318
			c0.040849-0.005112,0.081747-0.010231,0.122595-0.015358c0.207255,0.440598,0.389187,0.895439,0.627242,1.318695
			c0.36502,0.648872,1.173792,0.934792,1.868259,0.737251c1.343013,6.83239,7.278346,11.958794,14.46128,11.983849
			c8.21117,0.028641,14.861721-6.594353,14.88438-14.822594C44.032124,57.494656,37.400967,50.802631,29.339674,50.763504z
			 M29.164324,71.751671c-3.365017-0.003128-6.13088-2.768326-6.143993-6.142586
			c-0.013266-3.412312,2.750286-6.195473,6.15967-6.203308c3.378784-0.00779,6.165802,2.740753,6.185246,6.099854
			C35.385242,68.958893,32.609432,71.754875,29.164324,71.751671z" fill="currentColor"/>
		<path d="M29.171158,61.876446c-2.018137,0.010059-3.67251,1.667046-3.679644,3.685505
			c-0.007286,2.052292,1.672058,3.729378,3.723959,3.718964c2.018589-0.010239,3.672306-1.667,3.679443-3.686264
			C32.902203,63.539948,31.225922,61.866196,29.171158,61.876446z" fill="currentColor"/>
		<path d="M78.628952,69.285461c-1.380348-0.023453-2.516808,1.098152-2.510925,2.47802
			c0.005722,1.340797,1.119225,2.452751,2.459625,2.456299c1.341705,0.00354,2.460228-1.100388,2.473747-2.441483
			C81.064911,70.436867,79.968147,69.308212,78.628952,69.285461z" fill="currentColor"/>
		<path d="M43.737194,30.275484c-0.000401,0-0.000805,0-0.001205,0c-0.918858,0-1.784252,0.36467-2.437019,1.02688
			c-0.64352,0.652866-0.990604,1.506306-0.977337,2.403355c0.027733,1.865646,1.558155,3.385921,3.411343,3.389034
			c0.001808,0,0.003616,0,0.005424,0c0.893131,0,1.774609-0.374111,2.419132-1.026878
			c0.650352-0.658794,1.001255-1.51545,0.988194-2.4123C47.11779,31.760788,45.620529,30.276089,43.737194,30.275484z"/>
		<path d="M69.044167,32.56358c1.977142,0.020851,3.581421-1.533436,3.601616-3.489357
			c0.020149-1.958273-1.561165-3.563469-3.521172-3.574347c-1.943123-0.010777-3.518867,1.544853-3.538559,3.493429
			C65.566353,30.948675,67.110741,32.543205,69.044167,32.56358z" fill="currentColor"/>
		<path d="M8.707622,58.28833c1.933426-0.020374,3.477865-1.614906,3.458119-3.570274
			c-0.019695-1.948574-1.595437-3.504208-3.53856-3.493431c-1.960005,0.01088-3.541323,1.616077-3.521125,3.574348
			C5.126204,56.754883,6.730483,58.30917,8.707622,58.28833z" fill="currentColor"/>
	</g>
</g>
</svg>
    )
}