import React, { useState, useContext } from "react";
import Claim from "../../assets/vector/claim.svg"

const Button = ({ link, title, children, handleClick }) => {

    return (
        <button onClick={handleClick} title={title} className="claim_btn hover:scale-105 transition duration-150 ease-out">
            {children}
        </button>
    )
}

export default Button;