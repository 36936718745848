import React, { useEffect } from 'react';
import buttonClose from "../../assets/vector/closeButton.svg";

export default function Modal({ loadingDisable, modal, setModal, screen, children, }) {
    return (
        <div className={`fixed h-[100vh]  inset-0 z-[150]  bg-opacity-50 text-right ${!modal ? "hidden" : ""}`}>

            <div className="absolute inset-0 bg-gray-900 bg-opacity-60 max-h-[100vh]" style={{ backdropFilter: "blur(7px)" }}>
                .
            </div>
            {!loadingDisable && <div onClick={() => setModal(false)}><img src={buttonClose} className="absolute z-[200] right-2 top-2 hover:cursor-pointer" alt="close-icon" /></div>}
            <div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 md:mt-0 overflow-hidden z-[199] scroll-hidden" tabindex="-1">
                <div className=" py-5 px-0 md:px-10 rounded-md ">
                    {children}
                </div>
            </div>
        </div>
    )
}