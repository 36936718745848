import React from "react";

export default function mintIcon()
{
    return (
        <svg viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_743_16286)">
        <path d="M28.0171 40H32.7862C33.4668 40 34.0236 39.4432 34.0236 38.7626V20.9923C34.0236 20.3563 33.5081 19.8408 32.8721 19.8408H28.0171V40Z" fill="currentColor"/>
        <path d="M25.4393 19.8408H14.5605V40H25.4393V19.8408Z" fill="currentColor"/>
        <path d="M5.97607 38.7626C5.97607 39.4432 6.5329 40 7.21347 40H11.9826V19.8408H7.12754C6.4916 19.8408 5.97607 20.3564 5.97607 20.9923V38.7626Z" fill="currentColor"/>
        <path d="M36.0859 9.01362H26.7586C27.4742 8.58555 28.0736 8.15448 28.4296 7.81336C29.8082 6.49248 29.8549 4.30416 28.5341 2.9256C27.2132 1.54705 25.0249 1.50028 23.6463 2.82116C22.9181 3.5189 21.7706 5.21665 21.0445 6.86645C20.2283 4.64391 18.5712 2.12005 17.5701 1.16078C15.9047 -0.434917 13.2611 -0.378433 11.6654 1.28695C10.0697 2.95233 10.1262 5.59594 11.7916 7.19164C12.3254 7.70315 13.312 8.382 14.4584 9.01362H3.91366C3.2331 9.01362 2.67627 9.57044 2.67627 10.251V16.0964C2.67627 16.6341 3.02446 17.0933 3.50604 17.2629H36.4935C36.9751 17.0933 37.3233 16.6341 37.3233 16.0964V10.251C37.3233 9.57044 36.7664 9.01362 36.0859 9.01362Z" fill="currentColor"/>
        </g>
        <defs>
        <clipPath id="clip0_743_16286">
        <rect width="40" height="40" fill="white"/>
        </clipPath>
        </defs>
        </svg>
    )
}
