import React from "react";

export default function tractorIcon()
{
    return (
        <svg version="1.0" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	    viewBox="0 0 96 96" style="enable-background:new 0 0 96 96;" xmlSpace="preserve">
            <g>
                <g>
                    <path fill="currentColor" d="M88.14545,58.28583c-1.78471-0.84655-3.78326-1.3222-5.89091-1.3222
                        c-4.29381,0-8.12509,1.96801-10.64725,5.04873c-1.09091,1.3309-1.93309,2.87128-2.46547,4.55127
                        c-0.41018,1.30909-0.63273,2.70109-0.63273,4.14545c0,7.59274,6.15274,13.74545,13.74545,13.74545
                        C89.84728,84.45454,96,78.30183,96,70.70909C96,65.224,92.78837,60.48946,88.14545,58.28583z M82.25455,76.20726
                        c-3.03708,0-5.49818-2.46107-5.49818-5.49817c0-0.57774,0.08901-1.13454,0.25311-1.65818
                        c0.21293-0.67199,0.54982-1.28815,0.98618-1.8205c1.00885-1.23231,2.54137-2.01949,4.25889-2.01949
                        c0.84306,0,1.64249,0.19024,2.35637,0.52886c1.85716,0.88146,3.14183,2.77526,3.14183,4.96931
                        C87.75275,73.74619,85.29165,76.20726,82.25455,76.20726z"/>
                    <path fill="currentColor" d="M34.66036,61.90327c-0.23126-0.816-0.51928-1.60581-0.86399-2.36945H33.792
                        c-1.36145-3.03273-3.55635-5.61163-6.29672-7.44436c-0.93819-0.63273-1.94619-1.17382-3.00655-1.61892
                        c-1.60145-0.66762-3.32509-1.11272-5.12727-1.27854c-0.55416-0.05673-1.11709-0.08291-1.68872-0.08291
                        c-3.65236,0-7.05163,1.10835-9.86618,3.00655c-1.68872,1.1389-3.17237,2.56145-4.37674,4.20218
                        C1.2742,59.24581,0,62.86765,0,66.78181c0,6.60655,3.62181,12.36656,8.99345,15.39927
                        c2.56148,1.44873,5.52438,2.27346,8.67928,2.27346c9.76145,0,17.67273-7.91127,17.67273-17.67273v-0.21818
                        C35.32364,64.94909,35.08802,63.3869,34.66036,61.90327z M24.74182,66.78181c0,3.90459-3.16451,7.0691-7.0691,7.0691
                        c-1.26195,0-2.44711-0.32991-3.47171-0.9094c-2.14866-1.21307-3.59739-3.51707-3.59739-6.1597
                        c0-1.56567,0.50969-3.0144,1.37195-4.18559c0.48175-0.6563,1.0752-1.22533,1.75067-1.68087
                        c1.12583-0.7593,2.48555-1.20264,3.94648-1.20264c0.22865,0,0.45383,0.01047,0.67551,0.03316
                        c0.72086,0.06635,1.41032,0.24439,2.05089,0.51142c0.42417,0.17804,0.82734,0.3945,1.20264,0.64757
                        c1.09616,0.73311,1.9741,1.76466,2.51868,2.97776h0.00176c0.13788,0.30546,0.2531,0.62139,0.3456,0.94778
                        c0.17107,0.59344,0.2653,1.21832,0.27403,1.86414V66.78181z"/>
                    <path fill="currentColor" d="M92.43491,41.08365h-0.00436c-3.78764-1.82837-7.79781-3.26402-11.98256-4.25455
                        c-2.11835-0.50423-4.28387-0.88732-6.48435-1.15313v-2.62143h-9.6v2.23402c-0.60112,0.02415-1.2012,0.05302-1.79782,0.09617
                        c-1.15635,0.07854-2.30399,0.192-3.43855,0.34035V25.2h-0.87273v-7.41818c0-1.95053,0.87273-2.63563,2.83636-2.63563v-2.55274
                        c-0.21381-0.03489-0.432-0.04799-0.65454-0.04799c-0.78545,0-1.52727,0.18763-2.18182,0.52364
                        c-1.55346,0.79418-2.61818,2.40871-2.61818,4.27636V25.2h-0.87273v11.25381c-0.73309,0.15274-1.46618,0.31857-2.19053,0.49746
                        c0.00434-0.20508,0.00871-0.41455,0.00871-0.624c0-5.10109-1.57963-9.84-4.27636-13.74546
                        c-0.21919-0.31691-0.44984-0.62522-0.68339-0.93116l3.21429-3.21429h-6.15272h-6.21091H19.41818l1.74545,3.27273
                        c-0.08355,0.27883-0.16095,0.57472-0.23728,0.87273c-0.68107,2.65872-1.07181,6.00019-1.07181,8.72727
                        c0,2.09148,0.2091,4.13501,0.60938,6.10909c0.00059,0.00288,0.00096,0.00586,0.00154,0.00874
                        c-2.79711,0.04799-5.48073,0.56727-7.97237,1.47925c-2.7098,0.98619-5.19708,2.43929-7.36582,4.25891
                        c-1.29599,1.0822-2.48291,2.29964-3.53453,3.63055c-0.04365,0.35782-0.06547,0.72438-0.06547,1.09528
                        c0,1.43999,0.33164,2.80582,0.92509,4.01892c0.02136-0.02663,0.04562-0.05076,0.06712-0.07729
                        c0.27765-0.34203,0.56649-0.67426,0.86567-0.99711c0.07069-0.07628,0.14324-0.15045,0.21512-0.22561
                        c0.2988-0.31265,0.60525-0.61774,0.92354-0.9106c0.01958-0.01795,0.03798-0.03705,0.05763-0.05494
                        c0.3506-0.32006,0.71556-0.62418,1.08827-0.91899c0.05106-0.04027,0.10185-0.08065,0.15328-0.12044
                        c0.70161-0.54402,1.43989-1.04201,2.21114-1.49062c0.10036-0.05851,0.20068-0.11692,0.30213-0.17371
                        c0.13317-0.07439,0.26705-0.14736,0.40206-0.21879c0.13503-0.07157,0.27158-0.14063,0.4084-0.20918
                        c0.11263-0.05633,0.22428-0.1142,0.33814-0.16848c0.2417-0.1154,0.48625-0.22559,0.73314-0.33142
                        c0.0909-0.03891,0.18311-0.07521,0.27472-0.11282c0.1858-0.07639,0.37274-0.1504,0.56125-0.2213
                        c0.0945-0.0355,0.18904-0.07066,0.28418-0.10475c0.22332-0.08011,0.44848-0.15623,0.6754-0.22854
                        c0.053-0.01688,0.10523-0.03542,0.15844-0.05185c0.28152-0.08715,0.56607-0.16729,0.85275-0.24215
                        c0.07175-0.01875,0.14419-0.03556,0.21626-0.05353c0.22567-0.05625,0.45272-0.10872,0.68134-0.15725
                        c0.08224-0.01747,0.16441-0.03505,0.24703-0.05148c0.27441-0.05463,0.55041-0.10469,0.82876-0.148
                        c0.02176-0.00338,0.0432-0.00775,0.06499-0.01105c0.30021-0.04568,0.60309-0.0831,0.90754-0.11533
                        c0.08182-0.00866,0.16409-0.01545,0.24617-0.02312c0.23155-0.02165,0.46414-0.03934,0.69793-0.05305
                        c0.08813-0.00517,0.1761-0.01084,0.26455-0.01488C17.48768,44.40948,17.79721,44.4,18.10909,44.4
                        c0.35782,0,0.71564,0.00874,1.0691,0.02618c0.30296,0.01667,0.60352,0.04189,0.90277,0.07132
                        c0.02834,0.00277,0.05716,0.00386,0.08547,0.00676c0.25643,0.02629,0.51006,0.06129,0.76345,0.097
                        c0.08003,0.01124,0.16127,0.0187,0.24098,0.0309c0.05417,0.00828,0.10715,0.02005,0.16119,0.02879
                        c1.17174,0.18921,2.30854,0.48103,3.40307,0.86378c0.06507,0.02269,0.13122,0.04296,0.196,0.06632
                        c0.18883,0.06826,0.37444,0.14291,0.56058,0.21658c0.11511,0.04549,0.23137,0.08863,0.34544,0.1362
                        c0.15807,0.06602,0.31326,0.13732,0.46928,0.20723c0.13772,0.06163,0.27622,0.12172,0.41226,0.18636
                        c0.14169,0.06738,0.28069,0.13924,0.42054,0.20982c0.14536,0.07332,0.29118,0.14579,0.43447,0.22255
                        c0.13458,0.0721,0.26665,0.14806,0.39939,0.22313c0.14252,0.08062,0.2853,0.16074,0.42563,0.24474
                        c0.13597,0.08134,0.2694,0.16622,0.40323,0.25068c0.13005,0.08211,0.26023,0.16388,0.38824,0.24889
                        c0.14462,0.09596,0.28666,0.19525,0.42861,0.29483c0.07274,0.05111,0.14774,0.09916,0.21975,0.1512
                        c0.04709,0.03364,0.09141,0.06938,0.13794,0.10318c0.09447,0.06936,0.18819,0.13951,0.28136,0.21046
                        c0.03859,0.0298,0.07924,0.05729,0.11743,0.08784l0.00437,0.00434c0.00684,0.00692,0.01433,0.01326,0.0212,0.02019
                        c0.7468,0.57952,1.4515,1.20985,2.10895,1.88715c0.00871,0.00687,0.01675,0.01454,0.02549,0.02141
                        c0.00437,0.00437,0.00437,0.00437,0.00874,0.00871c0.00437,0.00437,0.00437,0.00437,0.00874,0.00874
                        c0.00434,0.00437,0.00434,0.00437,0.00871,0.00437c0.06986,0.07263,0.13434,0.15038,0.20314,0.22404
                        c0.14057,0.15022,0.27856,0.30267,0.41452,0.45714c0.09918,0.11293,0.19842,0.22553,0.2951,0.34067
                        c0.16982,0.20185,0.33422,0.40816,0.49611,0.61667c0.0591,0.07623,0.12067,0.15026,0.17868,0.22734
                        c0.22271,0.29555,0.43735,0.59736,0.64378,0.9053c0.03252,0.04855,0.06253,0.09879,0.09463,0.14763
                        c0.17303,0.26303,0.34013,0.53011,0.50095,0.80159c0.05108,0.0863,0.10019,0.17371,0.15,0.26082
                        c0.14068,0.2458,0.27622,0.49469,0.40664,0.74689c0.04528,0.0876,0.09079,0.1749,0.13482,0.26324
                        c0.14376,0.28836,0.28058,0.58059,0.41069,0.87664c0.02072,0.04722,0.04328,0.09338,0.06366,0.14079
                        c0.30605,0.71144,0.57017,1.44468,0.79352,2.19575c0.0069,0.02325,0.01569,0.04568,0.02251,0.06895l0.04362,0.14835
                        c0.01207,0.04235,0.02032,0.08627,0.03212,0.12875c0.09527,0.34288,0.18204,0.68906,0.25928,1.03908
                        c0.01134,0.05132,0.02448,0.1019,0.0354,0.15338c0.08733,0.41119,0.16262,0.82665,0.22436,1.24672
                        c0.0049,0.03334,0.00804,0.0672,0.01278,0.10059c0.05342,0.37663,0.09481,0.75693,0.12701,1.13997
                        c0.00703,0.08414,0.01321,0.16835,0.0192,0.25278c0.02765,0.38667,0.0453,0.77589,0.05071,1.16828
                        c0.00027,0.02134,0.00256,0.04219,0.00277,0.06355h7.31783h0.00874h8.16871v3.05454h9.16364v-3.05454h3.552
                        c0.60219-1.96801,1.56656-3.7789,2.81892-5.35419c2.99345-3.78327,7.62762-6.20945,12.82907-6.20945
                        c2.56582,0,4.99637,0.58908,7.15638,1.6451c2.21236-2.78837,3.53453-6.3142,3.53453-10.15419
                        C92.72727,43.12146,92.62691,42.08728,92.43491,41.08365z M49.8982,47.41526c-0.08291,1.56656-0.24873,3.10693-0.49309,4.62111
                        h-5.93456c-1.5098-3.38182-3.75708-6.36219-6.54109-8.73164c-3.41039-2.91059-7.62651-4.90363-12.2645-5.60572
                        c-0.55747-2.00942-0.85747-4.12596-0.85747-6.31292c0-3.11378,0.61012-6.08339,1.70734-8.80427h0.01244h0.03097h18.14358
                        c2.78837,4.59926,4.74327,9.75272,5.65963,15.25528c0.3971,2.34764,0.60218,4.75635,0.60218,7.21745
                        C49.96363,45.84873,49.94182,46.63418,49.8982,47.41526z"/>
                    </g>
                <g>
                    <g>
                        <defs>
                            <path d="M-157.33173-2245h-142.33655C-709.09448-2245-1041-2576.90552-1041-2986.33179v-78.33643
                            C-1041-3474.09448-709.09448-3806-299.66827-3806h142.33655C252.09448-3806,584-3474.09448,584-3064.66821v78.33643
                            C584-2576.90552,252.09448-2245-157.33173-2245z"/>
                        </defs>
                        <clipPath>
                            <use xlinkHref="#SVGID_93_" style="overflow:visible;"/>
                        </clipPath>
                    </g>
                </g>
            </g>
            <g style="display:none;">
                <rect x="-83.22115" y="69.5" style="display:inline;fill:none;stroke:#4070B7;stroke-width:3;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;" width="167.56952" height="167.56952"/>
                <path style="display:inline;fill:none;stroke:#4070B7;stroke-width:3;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;" d="M-354.1806,341c0-150.05939,121.42615-271.48553,271.48553-271.48553h-0.63879
                c92.50719,0,167.36285,74.85564,167.36285,167.36285C84.02898,294.4295,37.45848,341-20.09371,341h0.63879
                c-35.30809,0-63.87894-28.57086-63.87894-63.87894c0-22.24411,17.99964-40.24374,40.24374-40.24374h-0.63879
                c13.41707,0,24.274,10.85692,24.274,24.27399c0,8.82703-7.14271,15.96976-15.96974,15.96976
                c-4.23697,0-7.66547-3.71423-7.66547-8.30426"/>
            </g>
        </svg>
    )
}