import React, { useContext, useState, useEffect, useRef } from "react"
import "../../assets/css/index.css";
import LeftBar from "./leftBar";
import TopBar from "./topBar";
import UserContext from "../../context/userContext/UserContext";
import Modal from '../elements/Modal';
import ModalDom from "../elements/ModalX/modal_dom";
import OracleClaim from "../elements/Claim";
import mechanicWaifu from "../../assets/img/tractorwaifu.png"
import fondoMantenimiento from "../../assets/img/fondoMantenimiento.png"
export default function Layout({ children, background, effects }) {

    const MaintanceScreen = () => 
        <div className=" w-full h-full relative ">
            <div className="absolute  w-screen h-screen top-0 z-[5] flex flex-wrap">
                <div className="w-full md:w-1/2 ">
                    <img src={mechanicWaifu} className="w-[100%] mx-auto " />
                </div>
                <div className="w-full md:w-1/2 ">
                    <h1 className="font-bold text-[100px] text-yellow-500 " style={{ textShadow: "0px 0px 5px #fff" }}>UNDER</h1>
                    <p className="font-bold text-[80px] text-white" style={{ textShadow: "0px 0px 5px #fff" }}>MAINTENANCE</p>
                </div>
            </div>

            <img src={fondoMantenimiento} className="absolute w-[100vw] h-[100vh] z-[1]" />
        </div>
    

    const { menuOpen, changeOpenMenu, oracleOpen, changeOpenOracle, modalBg } = useContext(UserContext);



    return (<>
        <div className={modalBg ? 'blur-md' : ''}>
            <div className="relative w-full h-full">
                <img src={background} className={`fixed inset-0 w-full h-full bg-cover -z-50 ${effects}`} alt="background" />
            </div>

            <LeftBar menuOpen={menuOpen} changeOpenMenu={changeOpenMenu} changeOpenOracle={changeOpenOracle} />

            <div className="w-full sidebarCollapse ease-in-out duration-300">
                <TopBar changeOpenMenu={changeOpenMenu} oracleOpen={oracleOpen} changeOpenOracle={changeOpenOracle} />
                <section className="content-container">
                    {children}
                </section>
            </div>

            <Modal modal={oracleOpen} setModal={changeOpenOracle}>{oracleOpen && <OracleClaim setModal={changeOpenOracle} />}</Modal>
        </div>
        <ModalDom />
    </>
    )
}