import React from "react";

const Loader = () => {
    return(
        <div>
            <div className="loader">
                <div className="loader_dot"></div>
                <div className="loader_dot"></div>
                <div className="loader_dot"></div>
                <div className="loader_dot"></div>
                <div className="loader_dot"></div>
                <div className="loader_dot"></div>
            </div>
        </div>
    )
}

export default Loader;