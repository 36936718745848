import { Link, navigate } from "gatsby";
import React, { useState, useContext } from "react";
import UserContext from "../../context/userContext/UserContext";
import arrowIcon from "../../assets/vector/leftBarIcons/arrowIcon.svg";

const OptionList = ({
    titleIcon, title, options
}) => {
    const { menuOption, changeOpenMenu, changeMenuOption, sectionSelect, changeSectionSelect } = useContext(UserContext);

    const [active, setActive] = useState(sectionSelect == title ? true : false);
    const Options = ({ option }) => (
        <div className="test-button cursor-pointer" to={option.link} onClick={() => { changeSectionSelect(title); changeMenuOption(option.link); navigate(option.link); changeOpenMenu(false) }}>
            <div className={`flex flex-row text-center py-2 pl-8 items-center hover:bg-primaryDark hover:text-backgroundOp ${option.link === menuOption ? "bg-primaryDark text-backgroundOp " : "text-buttonBar"}`}>
                <div className="w-6">
                    {option.icon}
                </div>
                <span className="pl-3 option-text text-sm">{option.text}</span>
            </div>
        </div>
    )

    return (
        <div className="bg-backgroundOp">
            <div className="flex flex-row m-4 text-center cursor-pointer" onClick={() => setActive(!active)}>
                <img src={arrowIcon} alt={'Arrow Icon'} className={`w-3 ease-in-out duration-300 ${active ? 'rotate-90' : ''}`} />
                <img src={titleIcon} alt={title + ' Icon'} className="w-6 mx-2" />
                <h3 className="text-base text-darkPink font-bold">{title}</h3>
            </div>
            <div className={`${!active ? 'hidden' : ''}`}>
                {options && options.map((option, i) => <Options option={option} key={i} />)}
            </div>
        </div>
    )
}

export default OptionList;