import React from "react";
import { Link } from 'gatsby';
import Paper from "../../assets/vector/paper.svg"
import Geeko from "../../assets/vector/coinGecko.svg"
import Biswap from "../../assets/vector/biswap.svg"
import CoinMc from "../../assets/vector/coinMC.svg"
import Pancake from "../../assets/vector/pancake.svg"

const exchangesIcon = () => {
    return (
        <div className="flex">
            {/*  <img className="w-7 mx-2 cursor-pointer hover:scale-125 active:scale-95" src={Geeko} alt="geeko-swap" />
            <img className="w-7 mx-2 cursor-pointer hover:scale-125 active:scale-95" src={Biswap} alt="biswap-swap" />
            <img className="w-7 mx-2 cursor-pointer hover:scale-125 active:scale-95" src={CoinMc} alt="coinmc-swap" /> */}
            <img className="w-7 mx-2 cursor-pointer hover:scale-125 active:scale-95" src={Pancake} alt="pancake-swap" />
            <Link to="https://docs.darlingwaifu.com/" >
                <img className="w-7 mx-5 cursor-pointer hover:scale-125 active:scale-95" src={Paper} alt="paper-swap" />

            </Link>
        </div>
    )
}

export default exchangesIcon;