
import React, { useState, useEffect, useContext } from "react";
import ModalClaimPeach from "../../components/ModalClaimPeach/ModalClaimPeach";

import Claim from "../../assets/vector/claim.svg";
import Wallet from "../../assets/vector/wallet.svg";
import PeachCoin from "../../assets/vector/peachCoin.svg";
import MenuCollapse from "../../assets/vector/menuCollapse.svg";

import Button from "../elements/button";
import ExchangesIcons from "../elements/exchangesIcons";
import UserContext from "../../context/userContext/UserContext";

const TopBar = ({ changeOpenMenu, oracleOpen, changeOpenOracle }) => {

    const { balance, account, topData } = useContext(UserContext);
    const [contractPeach, setContractPeach] = useState(null);
    const [modal, setModal] = useState(false);


    return (
        <>
            <ModalClaimPeach modal={modal} setModal={setModal} />

            <div className="bg-backgroundOp shadow-xl z-[20] relative flex justify-between p-1 items-center">
                <div className="block lg:hidden">
                    <button onClick={changeOpenMenu}><img alt="menu-icon" src={MenuCollapse} className="cursor-pointer hover:scale-125 active:scale-95" /></button>
                </div>
                <div className="hidden md:flex items-center">
                    <div>

                        <div className="bg-[#591830] text-xs font-semibold py-1 px-3 rounded-md text-white text-center shadow-lg mx-2">
                            <div className="py-2">MAX WAIFU POWER: <span className="text-[#F0C1DC] ml-2">{topData ? topData.waifuPower : 0} WP</span></div>
                            {/*  <div className="text-[#ABEEFC]">{topData ? topData.balanceTractor : 0}</div> */}
                        </div>

                    </div>
                    <div>
                        <div className="bg-[#591830] text-xs font-semibold py-1 px-3 rounded-md text-white text-center shadow-lg mx-2">
                            <div className="py-2">CURRENT TRACTORS: <span className="text-[#ABEEFC] ml-2">{topData ? topData.balanceTractor : 0}</span> </div>
                            {/*  <div className="text-[#F0C1DC]"></div> */}
                        </div>
                    </div>
                </div>
                <div className="flex items-center">
                    <div className="hidden sm:block mr-3">
                        <ExchangesIcons />
                    </div>
                    {/*  <div className="hidden xl:block mr-2" onClick={() => { setModal(true) }}>
                        <Button>
                            <img src={Claim} className="mr-1" width="20" height="auto" alt="claim-icon" />
                            <span className="font-bold text-sm">Claim $PEACH</span>
                        </Button>
                    </div> */}
                    <div className="hidden xl:block mr-2" onClick={changeOpenOracle}>
                        <Button>
                            <img src={Claim} className="mr-1" width="20" height="auto" alt="claim-icon" />
                            <span className="font-bold text-sm">Claim HARVEST</span>
                        </Button>
                    </div>
                    <div className="flex justify-end items-center">
                        <div className="hidden lg:block">
                            <img src={PeachCoin} width="60" height="60" alt="peach-icon" />
                        </div>
                        <div className="flex flex-col">
                            <div className="text-base font-bold flex justify-end">
                                <span className="text-[#AA2E74] mr-1">{parseInt(topData?.balancePeach) || 0}</span>
                                <span>$PEACH</span>
                            </div>

                            <div className="text-lg font-semibold flex items-center justify-end">
                                <img className="" src={Wallet} width="16" height="auto" alt="wallet-icon" />
                                <span className="text-[#006672] text-sm">{account ? account.slice(0, 8) + '...' : '0x000000...'}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default TopBar
